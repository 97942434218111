$my-primary-color: #FDF4EC;
$my-secondary-color: #6E9C54;
$my-primary-text: #444444;
$my-secondary-text: #FDF4EC;
$button-active: #F1E1D3;

.details-wrapper {
  margin-bottom: 3rem;

  &.details-wrapper-desktop {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &.details-wrapper-mobile {
    width: 100%;
  }

  .details-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.details-item-desktop {
      width: 22%;
    }

    &.details-item-mobile {
      width: 100%;
    }

    &.mt-5 {
      margin-top: 3rem;
    }

    .details-item-icon-wrapper {
      background-color: $button-active;
      border-radius: 100%;
      height: 70px;
      width: 70px;
      margin-bottom: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .details-item-icon {
        display: block;
        color: $my-secondary-color;
        height: 30px;
        width: 30px;
        font-size: 30px;
      }
    }

    .details-item-label-wrapper {
      margin-bottom: 0.5rem;

      .details-item-label {
        display: block;
        font-weight: 700;
        font-size: 1rem;
        color: $my-secondary-color;
      }

      hr {
        margin: 0.5rem 0 0 0;
      }
    }

    .details-item-text {
      text-align: center;
      color: $my-primary-text;
    }
  }
}

.location-label-wrapper {
  text-align: center;
  margin-bottom: 1rem;

  .line {
    margin: 0 0 0.8rem 0;
  }

  .location-label {
    font-weight: 700;
    color: $my-secondary-color;
    font-size: 1rem;
  }
}

.maps-desktop {
  height: 500px;
}

.maps-mobile {
  height: 450px;
}

.text-decoration-underline {
  text-decoration: underline;
}

.fun-fact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem !important;

  &.fun-fact-item-desktop {
    width: 50%;
    margin: auto;
  }

  &.fun-fact-item-mobile {
    width: 100%;
  }

  .fun-fact-item-icon-wrapper-wrapper {
    display: flex;

    .fun-fact-item-icon-wrapper {
      background-color: $button-active;
      border-radius: 100%;
      height: 70px;
      width: 70px;
      margin-bottom: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .fun-fact-item-icon {
        display: block;
        color: $my-secondary-color;
        height: 30px;
        width: 30px;
        font-size: 30px;
      }
    }
  }

  .fun-fact-item-label-wrapper {
    margin-bottom: 0.5rem;

    .fun-fact-item-label {
      display: block;
      font-weight: 700;
      font-size: 1rem;
      color: $my-secondary-color;
    }

    hr {
      margin: 0.5rem 0 0 0;
    }
  }

  .fun-fact-item-text {
    text-align: center;
    color: $my-primary-text;
  }
}
