$my-secondary-color: #6E9C54;
$my-primary-text: #444444;
$my-secondary-text: #FDF4EC;
$button-active: #F1E1D3;
$secondary-button-hover: #5A8144;

.contact-content-wrapper {
  width: 740px;
  margin: auto;

  .contact-wrapper {
    margin-bottom: 3rem;

    &.contact-wrapper-desktop {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.contact-wrapper-mobile {
      width: 100%;
    }

    .contact-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.contact-item-desktop {
        width: 40%;
      }

      &.contact-item-mobile {
        width: 100%;
      }

      &.mt-5 {
        margin-top: 3rem;
      }

      .contact-item-icon-wrapper {
        background-color: $button-active;
        border-radius: 100%;
        height: 70px;
        width: 70px;
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .contact-item-icon {
          display: block;
          color: $my-secondary-color;
          height: 30px;
          width: 30px;
          font-size: 30px;
        }
      }

      .contact-item-label-wrapper {
        margin-bottom: 0.5rem;

        .contact-item-label {
          display: block;
          font-weight: 700;
          font-size: 1rem;
          color: $my-secondary-color;
        }

        hr {
          margin: 0.25rem 0 0 0;
        }
      }

      .contact-item-text {
        color: #D44840;
        text-decoration: none;
        cursor: pointer;
        font-weight: 600;
      }

      .eye-button {
        color: #969696;
        text-transform: none !important;
        padding: 0 16px !important;
        font-weight: 600 !important;
        line-height: 36px !important;

        &:hover {
          color: $my-primary-text;
        }
      }
    }
  }

  .get-in-touch-label-wrapper {
    text-align: center;
    margin-bottom: 1rem;

    .line {
      margin: 0 0 0.8rem 0;
    }

    .get-in-touch-label {
      font-weight: 700;
      color: $my-secondary-color;
      font-size: 1rem;
    }
  }

  .send-message-button-container {
    text-align: center;

    .send-message-button {
      background-color: $my-secondary-color;
      color: $my-secondary-text;
      font-family: Bodoni, serif;
      font-size: 1.2rem;
      letter-spacing: 0.1rem;
      width: 180px;
      padding: 0 16px;
      line-height: 36px;
      text-transform: none;

      &:hover {
        background-color: $secondary-button-hover;
      }
    }
  }
}

@font-face {
  font-family: "Bodoni";
  src: local("Bodoni"), url("../assets/Bodoni 72 Smallcaps Book.ttf") format("truetype");
}