$my-secondary-color: #6E9C54;
$my-secondary-text: #FDF4EC;
$secondary-button-hover: #5A8144;

.masonry-wrapper {
  position: relative;
  transition: top 2s ease-in-out,left 2s ease-in-out;
  top: 0;
  left: 0;

  .masonry-image {
    display: block;
    width: 100%;
    filter: drop-shadow(0px 2px 4px rgba(0,0,0,.25));
  }

  .image-preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .3s;
    background-color: #00000080;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    .preview-icon {
      font-size: 2.5rem;
      color: $my-secondary-text;
    }
  }
}

.masonry-button-wrapper {
  margin-top: 2rem;

  .thats-all-button {
    display: block !important;
    margin: auto !important;
    font-size: 1.2rem !important;
    background-color: #0000001f !important;
    width: 180px !important;
    height: 36px !important;
    line-height: unset !important;
  }
}