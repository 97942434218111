$my-primary-color: #FDF4EC;
$my-primary-text: #444444;
$my-secondary-color: #6E9C54;
$my-secondary-text: #FDF4EC;
$button-active: #F1E1D3;
$button-active-text: #D44840;
$loader: #D44840;
$secondary-button-hover: #5A8144;

#root {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-card {
  padding: 3rem 2rem !important;
  background: #F9F9F9 !important;
  color: #444444 !important;

  .h2-text {
    font-family: Avenir, serif !important;
    font-weight: 700 !important;
    font-size: 1.75rem !important;
    letter-spacing: .02rem !important;
    margin: 0 0 16px !important;
  }

  .p-text {
    font-family: Avenir, serif;
    line-height: 1.5;
    letter-spacing: .02rem;
    margin: 0 0 12px;

    &.m-0 {
      margin: 0 !important;
    }
  }

  .mb-5 {
    margin-bottom: 3rem !important;
  }

  .label {
    font-family: Avenir, serif;
    letter-spacing: 0.02rem;
  }
}

.hr {
  width: 100% !important;
  border-style: solid !important;
  border-color: #c0c0c0 !important;
  border-width: 0 !important;
  border-bottom-width: 1px !important;
}

.anchor {
  text-decoration: none;
  color: #D44840;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: #9D352F;
    text-decoration: underline;
  }

  .icon {
    font-size: 1.2rem !important;
    margin-left: 0.1rem;
  }
}

.red-text {
  color: #d44840 !important;
}

.help-text {
  color: #969696 !important;
  margin-bottom: 0.8rem !important;
  display: block !important;
  cursor: default !important;
}

.form-wrapper {
  width: 100% !important;
  margin-bottom: 2rem !important;
}

.fieldset {
  border: none;
  margin: 0;
  padding: 0;
  min-inline-size: unset;
}

.form-label {
  width: 100% !important;
  font-family: Avenir, serif !important;
  letter-spacing: 0.02rem !important;
  font-weight: 700 !important;
  color: $my-primary-text !important;
  display: block !important;
}

.form-input {
  width: 100% !important;
  background: #ffffff;

  .MuiInputBase-root {
    color: #495057 !important;
    font-family: Avenir, serif !important;
    letter-spacing: 0.02rem !important;
    padding: 0 !important;

    .MuiInputAdornment-root {
      margin-left: 0.5rem;
      margin-right: 0;

      p {
        color: #495057 !important;
        font-family: Avenir, serif !important;
        letter-spacing: 0.02rem !important;
      }
    }

    .MuiInputBase-input {
      padding: 0.5rem;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 3px;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid #6e9c54;
      box-shadow: 0 0 0 0.2rem #6e9c5433;
      transition: background-color .2s, color .2s, border .2s, box-shadow .2s !important;
    }

    &:hover:not(.Mui-disabled, .Mui-error) .MuiOutlinedInput-notchedOutline {
      border: 1px solid #6e9c54;
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #d44840 !important;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        box-shadow: 0 0 0 0.2rem #e24c4c33 !important;
        transition: background-color .2s, color .2s, border .2s, box-shadow .2s !important;
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border: 1px solid #d44840 !important;
      }
    }

    /* width */
    textarea::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    textarea::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    textarea::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    textarea::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.form-dropdown {
  width: 100% !important;

  &.MuiOutlinedInput-root {
    color: #495057 !important;
    background: #ffffff;
    font-family: Avenir, serif !important;
    letter-spacing: 0.02rem !important;

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 3px;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid #6e9c54;
      box-shadow: 0 0 0 0.2rem #6e9c5433;
      transition: background-color .2s, color .2s, border .2s, box-shadow .2s !important;
    }

    &:hover:not(.Mui-disabled, .Mui-error) .MuiOutlinedInput-notchedOutline {
      border: 1px solid #6e9c54;
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #d44840 !important;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        box-shadow: 0 0 0 0.2rem #e24c4c33 !important;
        transition: background-color .2s, color .2s, border .2s, box-shadow .2s !important;
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border: 1px solid #d44840 !important;
      }
    }
  }
}

.form-radio-group {
  align-items: flex-start;

  .MuiFormControlLabel-root {
    &:hover {
      .MuiButtonBase-root:not(.Mui-error):not(.Mui-disabled) {
        color: $my-secondary-color;
      }
    }

    .MuiButtonBase-root:not(.Mui-error) {
      padding: 2px 9px;
      color: #ced4da;

      &.Mui-checked {
        color: $my-secondary-color;
      }
    }

    &.Mui-error {
      .MuiButtonBase-root {
        color: #d44840;
      }
    }

    .MuiFormControlLabel-label {
      color: #495057 !important;
      font-family: Avenir, serif !important;
      letter-spacing: 0.02rem !important;
    }
  }
}

.form-small-text {
  align-items: center !important;
  color: #d44840 !important;
  background-color: #f2dede !important;
  padding: 0.3rem 0.8rem !important;
  cursor: default !important;

  &.d-flex {
    display: flex !important;
  }

  &.d-none {
    display: none !important;
  }
}

.form-icon {
  margin-right: 0.25rem;
}

fieldset[disabled] {
  > label, small, div {
    opacity: 0.6;
    cursor: default;
  }

  .MuiButton-root.Mui-disabled {
    color: #00000042 !important;
    background-color: #0000001f !important;
  }
}

.pen-container {
  overflow: hidden;
  margin: -1rem 0;

  .pen {
    transform: rotate(200deg);
    margin: 5% 0 -15% -5%;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  }
}

.sidenav-drawer {
  height: 100vh;
  width: 50vw;
  background-color: $my-primary-color;

  .sidenav-drawer-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
  }
}

.router-list-buttons {
  height: 48px !important;
  width: 100% !important;
  margin-bottom: 1.5rem !important;
  padding: 0 1rem !important;
  justify-content: flex-start !important;

  &:hover, &.active {
    background-color: $button-active !important;
    color: $button-active-text !important;
  }
}

.rsvp-button-mobile {
  margin-top: 2rem !important;
  line-height: 3rem !important;
  width: 100% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !important;
  background-color: $my-secondary-color !important;
  color: $my-secondary-text !important;
  height: 48px !important;
}

.bodoni-font-mobile {
  font-size: 1.3em !important;
}

.MuiDialogTitle-root {
  font-weight: 700 !important;
  font-family: Avenir, serif !important;
  letter-spacing: 0.02rem !important;
  font-size: 1.5rem !important;

  &.dialog-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .success-check {
      color: #50886B;
      font-size: 150px;
    }

    .failure-close {
      color: #D44840;
      font-size: 150px;
    }
  }
}

.MuiDialogContent-root {
  display: flex;
  align-items: flex-start;
  padding: 0 24px !important;

  &.dialog-content {
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .dialog-icon {
    margin-right: 0.5rem;
    color: #ffc107;
  }

  p {
    margin: 0;
    font-family: Avenir, serif;
    letter-spacing: 0.02rem;
    font-size: 14px;
  }
}

.MuiDialogActions-root {
  padding: 16px 24px !important;

  &.dialog-footer {
    display: flex;
    align-items: center;
  }

  &.success-footer {
    justify-content: center;
  }

  &.failure-footer {
    justify-content: space-between;
  }

  .primary-button {
    background-color: $my-secondary-color;
    color: $my-secondary-text;
    font-family: Bodoni, serif;
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
    text-transform: none !important;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;

    &:hover {
      background-color: $secondary-button-hover;
    }
  }

  .secondary-button {
    color: $my-primary-text;
    font-family: Bodoni, serif;
    letter-spacing: 0.1rem;
    font-size: 1.2rem;
    text-transform: none !important;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
  }
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url("./assets/AvenirNextLTPro-Regular.otf") format("opentype");
}
