$my-secondary-text: #FDF4EC;

#lightbox {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100000;
  transition: ease-out 0.3s;
  background-color: rgba(0, 0, 0, 0.9);

  &.lightbox-show {
    opacity: 1;
    visibility: visible;

    .lightbox-content .active {
      transform: scale(1);
    }
  }

  &.lightbox-hide {
    opacity: 0;
    visibility: hidden;

    .lightbox-content .active {
      transform: scale(0.25);
    }
  }

  .top-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    height: 72px;
    width: 100%;
    top: 0;
    left: 0;
    color: $my-secondary-text;
    z-index: 100002;
    transition: opacity 0.5s;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  .lightbox-content {
    position: fixed;
    height: calc(100% - 144px);
    top: 72px;
    z-index: 100000;

    &.lightbox-content-desktop {
      width: calc(100% - 144px);
      left: 72px;
    }

    &.lightbox-content-mobile {
      width: 100%;
      left: 0;
    }

    .lightbox-image-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      transition: 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;

      .lightbox-image {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        cursor: grab;
        touch-action: none;
      }
    }

    .active {
      left: 0;
      opacity: 1;
      transform: scale(1);
      transition: 0.5s;
    }

    .left-hidden {
      left: -100%;
      opacity: 0;
      transform: scale(0.25);
      transition: 0.5s;
    }

    .right-hidden {
      left: 100%;
      opacity: 0;
      transform: scale(0.25);
      transition: 0.5s;
    }
  }

  .nav-buttons-wrapper {
    color: $my-secondary-text;
    z-index: 100001;
    position: fixed;
    top: 0;
    width: 72px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .5s;

    &.button-right {
      right: 0;
    }

    &.button-left {
      left: 0;
    }

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  .counter-text {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    color: $my-secondary-text;
    font-weight: 500;
    z-index: 100001;
    padding: 1rem 0;
    font-family: Avenir, serif;
    line-height: 1.5;
    letter-spacing: .02rem;
    transition: opacity 0.5s;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }
}

.lightbox-icon {
  color: $my-secondary-text !important;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url("../../assets/AvenirNextLTPro-Regular.otf") format("opentype");
}