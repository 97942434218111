$rsvp-button-hover: #5A8144;
$my-secondary-color: #6E9C54;
$my-secondary-text: #FDF4EC;
$secondary-button-hover: #5A8144;

.home-container {
  .book-container {
    flex-shrink: 0;
    transform: rotate(-4.14deg);
    height: 50vh;
    width: 38.91vh;
    min-height: 400px;
    min-width: 304px;
    margin-right: 5vw;

    img {
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    }
  }

  .polaroids-text-container {
    margin-left: 5vw;

    .polaroids-container {
      display: flex;
      justify-content: center;

      .card-1 {
        transform: rotate(11.57deg);
      }

      .card-2 {
        transform: rotate(-7.64deg);
        margin-top: 27.97%;
      }

      .card-3 {
        transform: rotate(3.36deg);
        margin-top: 5%;
        margin-left: 5%;
      }

      .polaroid-card {
        flex-shrink: 0;
        height: 15vh;
        width: calc(15vh / 149 * 104);
        min-height: 120px;
        min-width: 83.36px;
        padding: 5% 2% 10% 2%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

        .polaroid-card-photo-container {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .polaroid-photo, .polaroid-frame {
          width: 100%;
          height: 100%;
          position: absolute;
          backface-visibility: hidden;
        }
      }
    }

    .polaroids-text {
      text-align: center;
      margin-top: 48px;

      .our-text {
        color: #F9F9F9;
        font-size: 36px;
        line-height: 36px;
        font-family: adorn-garland, sans-serif;
        letter-spacing: unset !important;
      }

      .join-us-button {
        height: 36px;
        margin: 2rem auto 0 auto;
        width: 180px;
        background-color: $my-secondary-color;
        color: $my-secondary-text;
        font-family: Bodoni, serif;
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
        text-transform: none !important;

        &:hover {
          background-color: $secondary-button-hover;
        }
      }
    }
  }
}

//mobile
.home-container-mobile {
  .book-container-mobile {
    margin-left: 3rem;
    transform: rotate(11.12deg);

    img {
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    }
  }

  .polaroids-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    .card-1-mobile {
      transform: rotate(11.57deg);
      margin-left: -40%
    }

    .card-2-mobile {
      transform: rotate(-7.64deg);
      margin-left: 50%;
      margin-top: -50%;
    }

    .card-3-mobile {
      transform: rotate(5.96deg);
      margin-left: -30%;
      margin-top: -66%;
    }

    .polaroid-card {
      flex-shrink: 0;
      height: calc(35vw / 104 * 149);
      width: 35vw;
      padding: 5% 2% 10% 2%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

      .polaroid-card-photo-container {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .polaroid-photo, .polaroid-frame {
        width: 100%;
        height: 100%;
        position: absolute;
        backface-visibility: hidden;
      }
    }

    .logo-container {
      flex-shrink: 0;
      height: calc(60vw / 160 * 41);
      width: 60vw;
      transform: rotate(8.07deg);
      padding: 3% 5%;
      margin-top: 12%;
      margin-left: 32%;
      z-index: 1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

      .logo-photo-container {
        position: relative;
        width: 100%;
        height: 100%;

        .logo-photo {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.rsvp-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;

  .our-text {
    color: #F9F9F9;
    font-size: 8vw;
    line-height: 8vw;
    font-family: adorn-garland, sans-serif;
    letter-spacing: unset !important;
  }

  .rsvp-button-home {
    margin-top: 2rem;
    height: 45px;
    width: 50vw;
    line-height: 11vw;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: $my-secondary-color;
    color: $my-secondary-text;

    &:hover {
      background-color: $rsvp-button-hover;
    }
  }

  .bodoni-font {
    font-family: Bodoni, serif !important;
    letter-spacing: 0.1rem !important;
  }

  .bodoni-font-mobile {
    font-size: 6vw !important;
  }
}

@font-face {
  font-family: "Bodoni";
  src: local("Bodoni"), url("../assets/Bodoni 72 Smallcaps Book.ttf") format("truetype");
}

@font-face {
  font-family: "adorn-garland";
  src: local("adorn-garland"), url("../assets/adorn-garland.otf") format("openType");
}
