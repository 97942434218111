$my-secondary-color: #6E9C54;
$my-secondary-text: #FDF4EC;
$secondary-button-hover: #5A8144;

.rsvp-form {
  width: 740px;
  margin: auto;

  .submit-rsvp-button-container {
    text-align: center;

    .submit-rsvp-button {
      background-color: $my-secondary-color;
      color: $my-secondary-text;
      font-family: Bodoni, serif;
      font-size: 1.2rem;
      letter-spacing: 0.1rem;
      width: 180px;
      padding: 0 16px;
      line-height: 36px;
      text-transform: none;

      &:hover {
        background-color: $secondary-button-hover;
      }
    }
  }
}