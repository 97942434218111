$my-primary-color: #FDF4EC;
$my-primary-text: #444444;
$my-secondary-text: #FDF4EC;
$button-hover: #F7EBE0;
$button-hover-text: rgba(68, 68, 68, 0.5);
$button-active: #F1E1D3;
$button-active-text: #D44840;

.text-color {
    color: $my-primary-text !important;
}

.bodoni-font {
    font-family: Bodoni, serif !important;
    letter-spacing: 0.1rem !important;
    text-transform: none !important;
}

.bodoni-font-desktop {
    font-size: 1.4em !important;
}

.current-page-text {
    font: 500 1.5rem/1.5rem Trebuchet MS, sans-serif !important;
    letter-spacing: 0.02rem !important;
    align-items: center;
    color: #000000de !important;
}

.app-bar {
    background-color: $my-primary-color !important;
    top: 0 !important;
    z-index: 1200 !important;
}

.router-buttons {
    line-height: 1.9 !important;
    padding: 0 16px !important;
    margin: 0 16px !important;

    &:hover {
        background-color: $button-hover !important;
        color: $button-hover-text !important;
    }

    &.active {
        background-color: $button-active !important;
        color: $button-active-text !important;

        &:hover {
            background-color: $button-hover !important;
            color: $button-hover-text !important;
        }
    }
}

.rsvp-button {
    background-image: url('./assets/bg-leaf-1.png');
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 11.5rem;
    line-height: 4.56rem !important;
    padding-right: 1.5rem;
    text-align: center;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    color: $my-secondary-text !important;
    text-decoration: none;

    &:hover {
        background-image: url('./assets/bg-leaf-2.png');
        color: rgba(253, 244, 236, 0.5) !important;
    }

    &.active {
        background-image: url('./assets/bg-leaf-3.png');

        &:hover {
            background-image: url('./assets/bg-leaf-2.png');
            color: rgba(253, 244, 236, 0.5) !important;
        }
    }
}

@font-face {
    font-family: "Bodoni";
    src: local("Bodoni"), url("./assets/Bodoni 72 Smallcaps Book.ttf") format("truetype");
}